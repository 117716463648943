import React, { useContext, useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button, Checkbox, Col, Form, Input, Row, Select, Tag, TreeSelect, Typography, Upload } from 'antd';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import TextArea from 'antd/lib/input/TextArea';
import { Country, State, City } from 'country-state-city';
import { Link, navigate } from 'gatsby';
import randomString from 'randomstring';
import LocalizedStrings from 'react-localization';
import SortableTree, { getTreeFromFlatData, TreeItem } from 'react-sortable-tree';
import store from 'store';
import en from 'world_countries_lists/data/countries/en/world.json';
import 'antd-country-phone-input/dist/index.css';

import { ClientHelmet } from '../components';
import { FormPageLayout } from '../components/Layouts';
import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';

const { SHOW_PARENT } = TreeSelect;
const { Title, Paragraph } = Typography;
const GATSBY_CLIENT_NAME = process.env.GATSBY_CLIENT_NAME || '';
const GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR = process.env.GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR!;
const GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM =
    process.env.GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM && process.env.GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM === 'true'
        ? true
        : false;
const DARK_THEME = process.env.GATSBY_STYLE_THEME === 'dark' ? true : false;
const GATSBY_STYLE_FORM_PAGE_FORM_COLOR = process.env.GATSBY_STYLE_FORM_PAGE_FORM_COLOR || 'white';
const GATSBY_GOOGLE_RECAPTCHA_KEY = process.env.GATSBY_GOOGLE_RECAPTCHA_KEY!;

const strings = new LocalizedStrings({
    en: {
        authNotFound: 'Auth module not found',
        registerSuccess: 'You have been registered. Please check your email for verification link. ',
        newVisitor: 'New Visitor',
        enterRegistration: 'Enter your registration details below',
        firstName: 'First Name',
        enterFirstName: 'Please enter your first name',
        lastName: 'Last Name',
        enterLastName: 'Please enter your last name',
        company: 'Company',
        enterCompany: 'Please enter the name of your company',
        email: 'Email Address',
        enterEmail: 'Please enter your email',
        password: 'Password',
        enterPassword: 'Please enter your password',
        profilePicture: 'Profile Picture',
        uploadPicture: 'Please upload your image',
        clickUpload: 'Click to upload',
        completeField: 'Please complete this field',
        register: 'Register',
        haveAccount: 'Already have an account? Log in here.',
        publicRegisterSuccess: 'You have been registered.',
        botCheck: 'Please verify you are not a bot',
    },
    de: {
        authNotFound: 'Authentifizierung konnte nicht erfolgen',
        registerSuccess: 'Sie wurden registriert. Bitte überprüfen Sie Ihre E-Mail auf den Bestätigungslink. ',
        newVisitor: 'Neuer Besucher',
        enterRegistration: 'Bitte geben Sie Ihre Daten ein',
        firstName: 'Vorname',
        enterFirstName: 'Bitte geben Sie Ihren Vornamen ein',
        lastName: 'Nachname',
        enterLastName: 'Bitte geben Sie Ihren Nachnamen ein',
        company: 'Unternehmen',
        enterCompany: 'Bitte geben Sie den Unternehmensnamen ein',
        email: 'E-Mail-Adresse',
        enterEmail: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
        password: 'Passwort',
        enterPassword: 'Bitte geben Sie Ihr Passwort ein',
        profilePicture: 'Profilbild',
        uploadPicture: 'Bitte laden Sie Ihr Bild hoch',
        clickUpload: 'Klicken zum Hochladen',
        completeField: 'Bitte füllen Sie dieses Feld aus',
        publicRegisterSuccess: 'Sie wurden registriert.',
        botCheck: 'Bitte verifizieren Sie, dass Sie kein Roboter sind',
        register: 'Registrieren',
        haveAccount: 'Haben Sie bereits ein Konto? Loggen Sie sich hier ein.',
    },
});

const Captcha = ({
    setCaptchaToken,
}: {
    setCaptchaToken: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
    return (
        <GoogleReCaptcha
            onVerify={(token: string) => {
                setCaptchaToken(token);
            }}
        />
    );
};

const PureCaptcha = React.memo(Captcha);

const RegisterPage = () => {
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const { eventAuthModule, fetchEvent } = context;
    const [authConfig, setAuthConfig] = useState<Modules.AuthModuleData>();
    const [registerForm] = Form.useForm();
    const breakpoint = useBreakpoint();
    const [captchaMandatory, setCaptchaMandatory] = useState<boolean>(false);
    const [captchaToken, setCaptchaToken] = useState<string>();

    useEffect(() => {
        fetchEvent()
            .then(({ extraUserProfileFields, eventAuthModule }) => {
                const authModuleId = eventAuthModule?._id!;
                registerForm.setFieldsValue({
                    extras: extraUserProfileFields.sort((f1, f2) =>
                        f2.ranking && f1.ranking ? f1.ranking - f2.ranking : 0,
                    ),
                });
                if (authModuleId) {
                    return apiRequester.getCaptchaCompulsion({ moduleId: authModuleId });
                } else return { enableCaptchaAtRegistration: false };
            })
            .then(({ enableCaptchaAtRegistration }: { enableCaptchaAtRegistration: boolean }) => {
                setCaptchaMandatory(enableCaptchaAtRegistration);
            })
            .catch(handleError)
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        eventAuthModule?._id && apiRequester.getAuthModuleConfig(eventAuthModule?._id).then(setAuthConfig);
    }, [eventAuthModule?._id]);

    const onFinishPrivateRegister = async ({
        firstName,
        lastName,
        company,
        emailId,
        password,
        extras = [],
        profilePicture,
    }: {
        firstName: string;
        lastName: string;
        company: string;
        emailId: string;
        password: string;
        extras: any;
        profilePicture: any[];
    }) => {
        try {
            setLoading(true);
            if (!eventAuthModule) throw new Error('Auth module not found');
            const extraFields = extras?.map((extra: any) => {
                return {
                    question: extra.question,
                    answers:
                        typeof extra.values === 'string'
                            ? [extra.values]
                            : extra.type === 'picture'
                            ? [extra.values[0].response]
                            : extra.values,
                    type: extra.type,
                };
            });

            await apiRequester.register({
                eventAuthModule,
                firstName,
                lastName,
                company,
                emailId,
                password,
                extras: extraFields,
                profilePicture: profilePicture?.length
                    ? {
                          link: profilePicture[0].response?.link,
                          name: profilePicture[0].response?.fileId,
                          s3Key: profilePicture[0].response?.s3Key,
                      }
                    : undefined,
                paramsString: typeof window !== 'undefined' ? window.location.search : '',
                captchaToken,
            });
            console.log('Private registration successful. Redirecting to the event...');

            registerForm.resetFields();
            registerForm.setFieldsValue({ extras: context.extraUserProfileFields });
            handleSuccess(strings.registerSuccess!);
            setLoading(false);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const onFinishPublicRegister = async ({
        firstName,
        lastName,
        company,
        emailId,
        extras = [],
        profilePicture,
    }: {
        firstName: string;
        lastName: string;
        company: string;
        emailId: string;
        extras: any;
        profilePicture: any[];
    }) => {
        try {
            setLoading(true);
            const extraFields = extras?.map((extra: any) => {
                return {
                    question: extra.question,
                    answers:
                        typeof extra.values === 'string'
                            ? [extra.values]
                            : extra.type === 'picture'
                            ? [extra.values[0].response]
                            : extra.values,
                    type: extra.type,
                };
            });

            const loginData = await apiRequester.publicSignIn({
                firstName,
                lastName,
                company,
                emailId,
                extras: extraFields,
                profilePicture: profilePicture?.length
                    ? {
                          link: profilePicture[0].response?.link,
                          name: profilePicture[0].response?.fileId,
                          s3Key: profilePicture[0].response?.s3Key,
                      }
                    : undefined,
            });
            await store.set('token', loginData.token);
            await store.set('refreshToken', loginData.refreshToken);
            await store.set('user', loginData.user);
            console.log('Public registration successful. Redirecting to the event...');

            registerForm.resetFields();
            registerForm.setFieldsValue({ extras: context.extraUserProfileFields });
            handleSuccess(strings.publicRegisterSuccess!);
            setLoading(false);
            navigate(`/${typeof window !== 'undefined' ? window.location.search : ''}`);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    const fileProps = {
        name: 'file',
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent?: number) => `${parseFloat(percent?.toFixed(2) || '0')}%`,
        },
        onChange({ file, fileList }: { file?: any; fileList: any }) {
            if (file.status === 'done') {
                setLoading(false);
            } else if (file.status === 'uploading') {
                setLoading(true);
                return;
            } else if (file.status === 'error') setLoading(false);
        },
        headers: { Authorization: 'Bearer ' + store.get('token') },
    };

    const normFile = (e: any) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const generateTreeData = (nodes: any, keyValueSame = false): any => {
        const result = [];
        const nodeCount = nodes.length;

        for (let i = 0; i < nodeCount; i++) {
            const node = nodes[i];
            const random = randomString.generate(3);
            if (node.type !== 'adder') {
                result.push({
                    title: node.value || node.title || node.name,
                    value:
                        node.children && node.children.length > 1
                            ? (node.value || node.title || node.name) + '---' + random
                            : node.value || node.title || node.name,
                    checkable: node.children && node.children.length > 1 ? false : true,
                    selectable: node.children && node.children.length > 1 ? false : true,
                    children: generateTreeData(node.children || []),
                });
            }
        }

        return result;
    };

    const generateTreeProps = (optionTree: any, keyValueSame = false) => {
        const tProps = {
            treeLine: { treeLine: true && { showLeafIcon: false } },
            treeData: generateTreeData(optionTree, keyValueSame),
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            style: {
                width: '100%',
            },
        };
        return tProps;
    };

    const generateTreePropsFromBoothTree = (optionTree: any) => {
        const boothTree = getTreeFromFlatData({
            flatData: (optionTree || []).map((node: TreeItem) => ({
                ...node,
                title: node.name,
                expanded: true,
                key: node.key,
            })),
            getKey: (node: TreeItem) => node.id,
            getParentKey: (node: TreeItem) => node.parent,
            rootKey: -1,
        });
        console.log({ boothTree });
        return generateTreeProps(boothTree || []);
    };

    const getTextColor = () => {
        return GATSBY_CLIENT_NAME === 'siemens' ? 'white' : DARK_THEME ? 'white' : undefined;
    };

    const getLabel = (label: string) => {
        return (
            <span
                style={{
                    color: getTextColor(),
                }}
            >
                {label}
            </span>
        );
    };

    return (
        <GoogleReCaptchaProvider reCaptchaKey={GATSBY_GOOGLE_RECAPTCHA_KEY}>
            <FormPageLayout>
                <ClientHelmet />
                <div style={{ marginBottom: '2rem' }}>
                    <Title
                        level={2}
                        style={{
                            marginBottom: '0',
                            color: getTextColor(),
                            textAlign: breakpoint.md ? 'left' : 'center',
                        }}
                    >
                        {strings.newVisitor}
                    </Title>
                    <Paragraph
                        style={{
                            color: getTextColor(),
                            textAlign: breakpoint.md ? 'left' : 'center',
                        }}
                    >
                        {strings.enterRegistration}
                    </Paragraph>
                </div>
                <Form
                    onFinish={GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM ? onFinishPublicRegister : onFinishPrivateRegister}
                    form={registerForm}
                >
                    <Row gutter={8} style={{ width: '100%' }}>
                        <Col span={GATSBY_CLIENT_NAME === 'siemens' ? 12 : 24}>
                            <Form.Item
                                label={getLabel(strings.firstName)}
                                labelCol={{ span: 24 }}
                                name="firstName"
                                rules={[{ required: true, type: 'string', message: strings.enterFirstName }]}
                                validateStatus={!['siemens'].includes(GATSBY_CLIENT_NAME) ? 'warning' : undefined}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={GATSBY_CLIENT_NAME === 'siemens' ? 12 : 24}>
                            <Form.Item
                                label={getLabel(strings.lastName)}
                                labelCol={{ span: 24 }}
                                name="lastName"
                                rules={[{ required: true, type: 'string', message: strings.enterLastName }]}
                                validateStatus={!['siemens'].includes(GATSBY_CLIENT_NAME) ? 'warning' : undefined}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        {!GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM && (
                            <Col span={GATSBY_CLIENT_NAME === 'siemens' ? 12 : 24}>
                                <Form.Item
                                    label={getLabel(strings.company)}
                                    labelCol={{ span: 24 }}
                                    name="company"
                                    rules={[{ required: true, type: 'string', message: strings.enterCompany }]}
                                    validateStatus={!['siemens'].includes(GATSBY_CLIENT_NAME) ? 'warning' : undefined}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={GATSBY_CLIENT_NAME === 'siemens' ? 12 : 24}>
                            <Form.Item
                                label={getLabel(strings.email)}
                                labelCol={{ span: 24 }}
                                name="emailId"
                                rules={[{ required: true, type: 'email', message: strings.enterEmail }]}
                                validateStatus={!['siemens'].includes(GATSBY_CLIENT_NAME) ? 'warning' : undefined}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        {!GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM && (
                            <Col span={GATSBY_CLIENT_NAME === 'siemens' ? 12 : 24}>
                                <Form.Item
                                    label={getLabel(strings.password)}
                                    labelCol={{ span: 24 }}
                                    name="password"
                                    rules={[{ required: true, message: strings.enterPassword }]}
                                    validateStatus={!['siemens'].includes(GATSBY_CLIENT_NAME) ? 'warning' : undefined}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                        )}
                        {!GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM && authConfig?.requireProfilePictureUpload && (
                            <Col span={GATSBY_CLIENT_NAME === 'siemens' ? 12 : 24}>
                                <Form.Item
                                    label={getLabel(strings.profilePicture)}
                                    labelCol={{ span: 24 }}
                                    name="profilePicture"
                                    rules={[{ required: false, message: strings.uploadPicture }]}
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    validateStatus={!['siemens'].includes(GATSBY_CLIENT_NAME) ? 'warning' : undefined}
                                >
                                    <Upload
                                        {...fileProps}
                                        action={apiRequester.getProfilePictureUploadUrl({
                                            moduleId: eventAuthModule?._id!,
                                        })}
                                        listType="picture"
                                        multiple={false}
                                        maxCount={1}
                                        onRemove={data => {
                                            apiRequester
                                                .deleteProfilePicture(eventAuthModule?._id!, data?.response?.fileId!)
                                                .catch(handleError);
                                        }}
                                    >
                                        <Button icon={<UploadOutlined />}>{strings.clickUpload}</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        )}
                        <Form.List name="extras" key="extras">
                            {fields => (
                                <>
                                    {fields?.map(field => {
                                        const fieldType = registerForm.getFieldValue('extras')[field.name].type;
                                        const isCountryCityType = fieldType === 'country-city';

                                        if (isCountryCityType) {
                                            return (
                                                <ConfigProvider locale={en}>
                                                    <Form.Item noStyle shouldUpdate key={`${field.key}-container`}>
                                                        {() => (
                                                            <Row gutter={8} style={{ width: '100%' }}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name={[field.name, 'values', 'country']}
                                                                        label={getLabel('Country')}
                                                                        labelCol={{ span: 24 }}
                                                                        rules={[
                                                                            {
                                                                                required: registerForm.getFieldValue(
                                                                                    'extras',
                                                                                )[field.name].required,
                                                                                message: strings.completeField,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select showSearch>
                                                                            {Country.getAllCountries().map(country => (
                                                                                <Select.Option
                                                                                    value={`${country.isoCode} - ${country.name}`}
                                                                                    key={country.isoCode}
                                                                                >
                                                                                    {country.flag} {country.name}
                                                                                </Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        shouldUpdate
                                                                        name={[field.name, 'values', 'city']}
                                                                        label={getLabel('City')}
                                                                        labelCol={{ span: 24 }}
                                                                        rules={[
                                                                            {
                                                                                required: registerForm.getFieldValue(
                                                                                    'extras',
                                                                                )[field.name].required,
                                                                                message: strings.completeField,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select showSearch>
                                                                            {(registerForm.getFieldValue('extras')[
                                                                                field.key
                                                                            ]?.values?.country
                                                                                ? City.getCitiesOfCountry(
                                                                                      registerForm
                                                                                          .getFieldValue('extras')
                                                                                          [
                                                                                              field.key
                                                                                          ]?.values?.country?.split(
                                                                                              ' - ',
                                                                                          )[0],
                                                                                  )!
                                                                                : []
                                                                            ).map(city => (
                                                                                <Select.Option
                                                                                    value={`${city.name}`}
                                                                                    key={city.name}
                                                                                >
                                                                                    {city.name}
                                                                                </Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Form.Item>
                                                </ConfigProvider>
                                            );
                                        } else {
                                            return (
                                                <Form.Item noStyle shouldUpdate key={`${field.key}-container`}>
                                                    {({ getFieldValue }) => {
                                                        return (
                                                            <Col
                                                                span={
                                                                    GATSBY_CLIENT_NAME !== 'siemens'
                                                                        ? 24
                                                                        : getFieldValue('extras')[field.name].type ===
                                                                              'checkbox' ||
                                                                          getFieldValue('extras')[field.name].type ===
                                                                              'country-city'
                                                                        ? 24
                                                                        : 12
                                                                }
                                                            >
                                                                <Form.Item
                                                                    {...field}
                                                                    label={
                                                                        getFieldValue('extras')[field.name].type !==
                                                                            'checkbox' &&
                                                                        getFieldValue('extras')[field.name].type !==
                                                                            'country-city'
                                                                            ? getLabel(
                                                                                  getFieldValue('extras')[field.key]
                                                                                      .question,
                                                                              )
                                                                            : undefined
                                                                    }
                                                                    key={getFieldValue('extras')[field.key]._id}
                                                                    name={[field.name, 'values']}
                                                                    labelCol={{ span: 24 }}
                                                                    valuePropName={
                                                                        getFieldValue('extras')[field.name].type ===
                                                                        'picture'
                                                                            ? 'fileList'
                                                                            : getFieldValue('extras')[field.name]
                                                                                  .type === 'checkbox'
                                                                            ? 'checked'
                                                                            : undefined
                                                                    }
                                                                    getValueFromEvent={
                                                                        getFieldValue('extras')[field.name].type ===
                                                                        'picture'
                                                                            ? normFile
                                                                            : undefined
                                                                    }
                                                                    rules={[
                                                                        {
                                                                            required: getFieldValue('extras')[
                                                                                field.name
                                                                            ].required,
                                                                            message: strings.completeField,
                                                                        },
                                                                        () => ({
                                                                            validator(_, value) {
                                                                                if (
                                                                                    value === false &&
                                                                                    getFieldValue('extras')[field.name]
                                                                                        .required &&
                                                                                    getFieldValue('extras')[field.name]
                                                                                        .type === 'checkbox'
                                                                                ) {
                                                                                    return Promise.reject(
                                                                                        strings.completeField,
                                                                                    );
                                                                                }

                                                                                return Promise.resolve();
                                                                            },
                                                                        }),
                                                                    ]}
                                                                    validateStatus={
                                                                        !['siemens'].includes(GATSBY_CLIENT_NAME)
                                                                            ? 'warning'
                                                                            : undefined
                                                                    }
                                                                    initialValue={
                                                                        getFieldValue('extras')[field.name].type ===
                                                                        'phone'
                                                                            ? { short: 'in' }
                                                                            : undefined
                                                                    }
                                                                >
                                                                    {getFieldValue('extras')[field.name].type ===
                                                                    'phone' ? (
                                                                        <ConfigProvider locale={en}>
                                                                            <CountryPhoneInput
                                                                                size="small"
                                                                                defaultValue={{ short: 'in' }}
                                                                            />
                                                                        </ConfigProvider>
                                                                    ) : getFieldValue('extras')[field.name].type ===
                                                                      'input' ? (
                                                                        <Input />
                                                                    ) : getFieldValue('extras')[field.name].type ===
                                                                      'textarea' ? (
                                                                        <TextArea />
                                                                    ) : getFieldValue('extras')[field.name].type ===
                                                                      'picture' ? (
                                                                        <Upload
                                                                            {...fileProps}
                                                                            action={apiRequester.getUserExtraProfileFieldFileUploadUrl(
                                                                                {
                                                                                    fieldId: getFieldValue('extras')[
                                                                                        field.name
                                                                                    ]._id,
                                                                                },
                                                                            )}
                                                                            listType="picture"
                                                                            multiple={false}
                                                                            maxCount={1}
                                                                            onRemove={data => {
                                                                                apiRequester
                                                                                    .deleteUserExtraProfileFieldFileUpload(
                                                                                        getFieldValue('extras')[
                                                                                            field.name
                                                                                        ]._id,
                                                                                        data?.response?.fileId!,
                                                                                    )
                                                                                    .catch(handleError);
                                                                            }}
                                                                        >
                                                                            <Button icon={<UploadOutlined />}>
                                                                                {strings.clickUpload}
                                                                            </Button>
                                                                        </Upload>
                                                                    ) : getFieldValue('extras')[field.name].type ===
                                                                      'select' ? (
                                                                        // @ts-ignore: Unreachable code error
                                                                        <TreeSelect
                                                                            {...generateTreeProps(
                                                                                getFieldValue('extras')[field.name].data
                                                                                    .optionTree,
                                                                                true,
                                                                            )}
                                                                        />
                                                                    ) : getFieldValue('extras')[field.name].type ===
                                                                      'checkbox' ? (
                                                                        <span>
                                                                            <Checkbox
                                                                                style={{ paddingRight: '1.5rem' }}
                                                                            />
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        GATSBY_CLIENT_NAME === 'siemens'
                                                                                            ? 'white'
                                                                                            : DARK_THEME
                                                                                            ? 'white'
                                                                                            : undefined,
                                                                                }}
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: getFieldValue('extras')[
                                                                                        field.name
                                                                                    ].question,
                                                                                }}
                                                                            ></span>
                                                                        </span>
                                                                    ) : getFieldValue('extras')[field.name].type ===
                                                                      'booth-tree' ? (
                                                                        // @ts-ignore: Unreachable code error
                                                                        <TreeSelect
                                                                            {...generateTreePropsFromBoothTree(
                                                                                getFieldValue('extras')[field.name].data
                                                                                    .optionTree,
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        // <Select>
                                                                        //     {getFieldValue('extras')[field.name].data &&
                                                                        //         getFieldValue('extras')[field.name].data.options &&
                                                                        //         registerForm
                                                                        //             .getFieldValue('extras')
                                                                        //             [field.name].data?.options?.map(
                                                                        //                 (option: Users.FieldOption) => (
                                                                        //                     <Select.Option
                                                                        //                         key={option._id!}
                                                                        //                         value={option._id!}
                                                                        //                     >
                                                                        //                         {option.title}
                                                                        //                     </Select.Option>
                                                                        //                 ),
                                                                        //             )}
                                                                        // </Select>
                                                                        <></>
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        );
                                                    }}
                                                </Form.Item>
                                            );
                                        }
                                    })}
                                </>
                            )}
                        </Form.List>
                        {GATSBY_CLIENT_NAME === 'siemens' && (
                            <Form.Item labelCol={{ span: 24 }}>
                                <span style={{ color: 'white' }}>
                                    By agreeing and clicking on the “Enter Virtual Campus” button, I hereby voluntarily
                                    consent to provide information that may be deemed as Personal Data under the
                                    Information Technology Act, 2000. I read and understood the Privacy Statement as
                                    above.
                                </span>
                            </Form.Item>
                        )}
                        {!GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM && captchaMandatory && (
                            <PureCaptcha setCaptchaToken={setCaptchaToken} />
                        )}
                        <Col span={GATSBY_CLIENT_NAME === 'siemens' ? 12 : 24}>
                            <Form.Item labelCol={{ span: 24 }}>
                                <Form.Item>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: breakpoint.md ? 'row' : 'column',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading}
                                            style={{
                                                borderColor: GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR,
                                                marginBottom: breakpoint ? '1rem' : undefined,
                                            }}
                                        >
                                            {GATSBY_CLIENT_NAME === 'siemens'
                                                ? 'Enter Virtual Campus'
                                                : strings.register}
                                        </Button>
                                        {!GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM && (
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}
                                            >
                                                <Link
                                                    style={{
                                                        color:
                                                            GATSBY_CLIENT_NAME === 'siemens'
                                                                ? 'white'
                                                                : DARK_THEME
                                                                ? 'white'
                                                                : undefined,
                                                    }}
                                                    to={`/login${
                                                        typeof window !== 'undefined' ? window.location.search : ''
                                                    }`}
                                                >
                                                    {strings.haveAccount}
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </Form.Item>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </FormPageLayout>
        </GoogleReCaptchaProvider>
    );
};

export default RegisterPage;
